<template>
  <div>

    <loader v-if="isLoading"/>

    <div
      class="container"
      v-else
    >
      <user-nav/>

      <div class="cart-box">

        <div class="cart-form">
          <form
            class="form-box form-box--cart"
            @submit.prevent="submitHandler"
          >
            <h6
              v-if="fields.length"
              class="form-box__title"
            >
              Для оформления заказа вам необходимо заполнить следующие поля:
            </h6>
            <div class="form-box__inner">
              <div
                class="form__field"
                v-for="field in fields"
                :key="field.id"
              >
                <div
                  v-if="field.type === 'file'"
                  class="form__input-file"
                >
                  <label
                    class="form__input-file__label"
                    :for="field.name"
                  >
                    <div class="form__input form__input--cart">
                      <svg-attach/>
                      <input
                        :id="field.name"
                        :type="field.type"
                        :required="field.is_required"
                        :multiple="field.multiselectable"
                        @change="formFields[field.name] = $event.target.files"
                      >
                      <label
                        class="form__input-label"
                        :for="field.name"
                      >{{ (formFields[field.name] ? `Выбрано файлов: ${formFields[field.name].length}` : '') || field.label }}</label>
                    </div>
                  </label>
                </div>
                <div
                  v-else
                  class="form__input-wrap form__input-wrap--cart"
                >
                  <input
                    :id="field.name"
                    :type="field.type"
                    :required="field.is_required"
                    v-model="formFields[field.name]"
                    class="form__input form__input--cart"
                    autocomplete="off"
                  >
                  <label
                    class="form__input-label"
                    :for="field.name"
                  >{{ field.label }}</label>
                </div>
              </div>

              <div class="form__agreements">
                <div class="form__checkbox-cart">
                  <input
                    id="denial_of_responsibility"
                    class="form__checkbox-cart__check"
                    type="checkbox"
                    required
                  >
                  <label for="denial_of_responsibility">Подтверждаю согласие третьих лиц на предоставление своих персональных данных</label>
                </div>

                <div class="form__checkbox-cart">
                  <input
                    id="privicy_policy_acceptance"
                    class="form__checkbox-cart__check"
                    type="checkbox"
                    required
                  >
                  <label for="privicy_policy_acceptance">
                    Подтверждаю согласие с <a :href="require(`@/assets/pdf/oferta.pdf`).default" target="_blank">договором оферты</a>
                    и <a :href="require(`@/assets/pdf/privacy_policy.pdf`).default" target="_blank">политикой конфиденциальности</a>
                  </label>
                </div>
              </div>

              <div class="cart-info__panel__promocode">
                <svg-promocode/>
                <input
                  id="promocode"
                  class="cart-info__panel__promocode-input"
                  :disabled="!!getPromocode"
                  v-model="promocode"
                  type="text"
                  placeholder="Промокод"
                  autocomplete="off"
                >
                <button
                  :class="[{'disabled': !!getPromocode}, 'btn', 'middle', 'blue']"
                  @click.prevent="submitPromocode(promocode)"
                >Применить</button>
              </div>

              <div class="cart-info">
                <h6 class="cart-info__title">В вашей корзине:</h6>
                <div class="cart-info__panel">
                  <div
                    v-for="item in cart"
                    :key="item.id"
                    class="cart-info__panel__item"
                  >
                    <div class="cart-info__panel__item-text">
                      <span class="cart-info__panel__item-text__title">{{ item.name }}</span>
                      <div class="cart-info__panel__item-text-wrap">
                        <i class="material-icons green-text">check</i>
                        <span class="cart-info__panel__item-text__price">{{ (item.quantity * item.price_discount) | formatPrice}}</span>
                      </div>
                      <span
                        v-if="item.price !== item.price_discount"
                        class="cart-item__info-cost cart-item__info-cost--basic"
                      >{{ (item.quantity * item.price) | formatPrice}}</span>
                    </div>
                    <button
                      class="btn grey small cart-item__remove"
                      @click.prevent="$bus.emit('removeFromCart', item.id)"
                    >Удалить</button>
                  </div>

                  <h6 class="cart-info__panel__total-price">
                    Итого: {{ totalPrice | formatPrice }}
                  </h6>


                </div>
              </div>

              <div class="form__btn-wrap">
                <button class="btn btn--order blue middle">
                  <svg-checked/>
                  <span>Оформить</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import UserNav from '@/components/UserNav'
import Loader from '@/components/Loader'
import SvgAttach from '@/assets/svg/attach.svg'
import SvgChecked from '@/assets/svg/checked.svg'
import SvgPromocode from '@/assets/svg/promocode.svg'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Cart',

  components: {
    Loader,
    SvgAttach,
    SvgChecked,
    SvgPromocode,
    UserNav
  },

  data: () => ({
    isLoading: true,
    promocode: null,
    formFields: {
      personal_data_policy_accepted: false,
      privicy_policy_accepted: false,
    }
  }),

  computed: {
    ...mapGetters({c: 'items'}),
    ...mapGetters(['getServices', 'getPackages', 'getPromocode']),
    products() {
      return [...this.getServices, ...this.getPackages]
    },
    cart() {
      return this.c.map(i => {
        let ext = this.products.find(j => j.id === i.id)
        return {...i, ...ext}
      })
    },
    totalPrice() {
      return this.cart.reduce((acc,i) => acc + i.price_discount, 0)
    },
    fields() {
      function extract (o) { return o?.fields || []; }

      let f = [];
      this.cart.map(c => {
        f = [...f, ...extract(c)]   // Extract fields of standalone services and self fields of packages
        c?.included_items?.map(i => {
          f = [...f, ...extract(i)] // Extract fields of included in package services
        })
      })

      return f.reduce((acc, i) => {
          if (acc.added.indexOf(i.id) === -1) {
            acc.added.push(i.id)
            acc.uniq.push(i)
          }
          return acc
        },
        {added:[], uniq:[]}
      ).uniq.sort((a,b) => (a.type==='file') ? 1 : -1)
    }
  },

  methods: {
    ...mapActions(['fetchCart', 'fetchServices', 'fetchPackages']),
    ...mapActions(['removeFromCart', 'createOrder']),
    ...mapActions(['fetchPromocode', 'applyPromocode']),
    async submitHandler() {
      let formData = new FormData()
      Object.keys(this.formFields).map(
        fieldName => (this.formFields[fieldName] instanceof FileList)
          ?
          Array.from(this.formFields[fieldName]).map((file, idx) => formData.append(`${fieldName}[${idx}]`, file))
          :
          formData.append(fieldName, this.formFields[fieldName])
      )

      try {
        await this.createOrder(formData)
      }
      catch (ex) {
        let errors = ex?.response?.data?.errors
        let error = errors?.order?.invalid

        if (error) M.toast({ html: error })
      }
    },
    async submitPromocode() {
      await this.applyPromocode(this.promocode)
      Promise.all([
        this.fetchServices(true),
        this.fetchPackages(true)
      ])
    }
  },

  watch: {
    cart() {
      if (!this.cart.length) this.$router.push('/shop')
    }
  },

  async mounted() {
    this.$bus.on('removeFromCart', this.removeFromCart)
    Promise.all([
      this.fetchServices(),
      this.fetchPackages(),
      this.fetchCart(),
      this.fetchPromocode()

    ])
    .finally(() => {
      this.isLoading = false
      this.promocode = this.getPromocode
      this.fields.map(i => Vue.set(this.formFields, i.name, null))

    })

    M.updateTextFields()
  },

  beforeDestroy() {
    this.$bus.off('removeFromCart', this.removeFromCart)
  }
}
</script>

<style lang="scss">
  .cart-box {
    display: flex;
    margin-top: 20px;
  }

  .form-box__title {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.3px;

    @include sm {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .cart-info__title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1.3px;

    @include sm {
      font-size: 11px;
      font-weight: 600;
    }
  }

  .cart-info {
    flex-grow: 1;
    margin: 30px 80px 20px;

    @include sm {
      margin: 20px 30px 15px;
    }
  }

  .cart-info__panel {
  }

  .cart-info__panel__item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }

  .cart-info__panel__item-text__title {
    font-weight: 600;
    text-align: left;
    padding-right: 10px;
  }


  .cart-info__panel__item-text {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .cart-info__panel__item-text-wrap {

    .material-icons {
      font-size: 18px;
      position: relative;
      top: 5px;
    }
  }

  .cart-info__panel__total-price {
    font-size: 16px;
    padding-top: 20px;
    text-align: left;
  }

  .cart-info__panel__promocode {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .cart-info__panel__promocode-input {
    background: unset;
    border: 0;
    border-bottom: 1px solid #959dc3;
    font-size: 16px;
    width: 350px;
    margin: 0 10px;

    &:focus {
      border-bottom: 1px solid #2F6BF0;
    }

    @include sm {
      width: 200px;
    }
  }
  @media all and (max-width: 992px) {
    .promocodes-block {
      padding-bottom: 75px;
    }
  }

  .form__field {
    width: 50%;

    @include sm {
      width: 100%;
    }
  }

  .form__agreements {
    text-align: left;
  }

  .form__checkbox-cart {
    display: inline-flex;
    align-items: baseline;
    text-align: left;
    line-height: 1.1;
    margin-left: 30px;

    @include sm {
      margin: 10px 30px;
    }
  }

  .cart-item__remove {
    height: 100%;
    text-decoration: underline;
  }
</style>
